<template>
  <div class="setting-item s-external-system-field">
    <esmp-switch
      v-model="localSetting.value"
      shape="circular"
      :label="localSetting.name"
    />
    <div
      v-if="localSetting.value && localSetting.childs"
      class="setting-item__child-settings"
    >
      <div
        v-for="child in localSetting.childs"
        :key="child.id"
        class="setting-item__child-setting setting-item__child-setting--small-offset"
      >
        <esmp-input
          v-if="child.techName === 'externalSystemFieldName'"
          v-model="child.value"
          :label="child.name"
        />
        <esmp-select
          v-if="child.techName === 'externalSystemName'"
          v-model="child.value"
          :placeholder="child.name"
        >
          <esmp-select-option
            v-for="op in child.options"
            :value="op.name"
            :key="op.id"
          >
            {{ op.name }}
          </esmp-select-option>
        </esmp-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SExternalSystemField',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/blocks/control-settings.scss';
</style>
